<template>
    <div class="box-grid">
        <div class="detalhes-do-agendamento">
            <p class="titulo">Detalhes do agendamento</p>
            <div class="card-dados overflow-auto p-3 w-full">
                <div class="flex flex-column w-full">
                    <div class="flex flex-row w-full">
                        <p class="subtitulo">Nome:</p>
                        <p class="subtitulo-dados">{{ dados.funcionario.name }}</p>
                    </div>
                    <div class="flex flex-row mt-2 w-full">
                        <p class="subtitulo">Tipo do Exame:</p>
                        <p class="subtitulo-dados">{{ dados.tipoExame.descricao }}</p>
                    </div>
                    <div class="flex flex-row mt-2 w-full">
                        <p class="subtitulo">Unidade:</p>
                        <p class="subtitulo-dados">{{ dados.funcionario.customerHierarchy.customerBranch.name }}</p>
                    </div>
                    <div class="flex flex-row mt-2 w-full">
                        <p class="subtitulo">Setor:</p>
                        <p class="subtitulo-dados">{{ dados.funcionario.customerHierarchy.customerDepartment.name }}</p>
                    </div>
                    <div class="flex flex-row mt-2 w-full">
                        <p class="subtitulo">Cargo:</p>
                        <p class="subtitulo-dados">{{ dados.funcionario.customerHierarchy.customerPosition.name }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="instrucao-de-atendimento">
            <p class="titulo">Instrução de atendimento</p>
            <DataTable :value="dados.agendamentoProcedimento" :scrollable="true" scrollHeight="29vh" responsiveLayout="scroll">
                <Column field="procedimentos.name" header="Exame"></Column>
                <Column field="recommendation" header="Recomendação"></Column>
            </DataTable>
        </div>
        <div class="resumo-do-atendimento">
            <p class="titulo">Resumo do atendimento</p>
            <DataTable :value="dados.agendamentoProcedimento" :scrollable="true" scrollHeight="34vh" responsiveLayout="scroll">
                <Column field="procedimentos.name" header="Procedimentos"></Column>
                <Column field="recommendation" header="Data e Hora">
                    <template #body="{ data }">
                        {{ $filters.formatDate(data.dataAgendamento) }}
                    </template>
                </Column>
                <Column field="prestador.name" header="Local"></Column>
            </DataTable>
        </div>
        <div class="footer flex align-content-end justify-content-between justify-content-center align-items-end">
            <Button label="Voltar" @click="voltar" class="p-button-rounded p-button-primaty btn-voltar align-self-end" />
            <Button label="Check-in" :loading="loading" @click="checkIn" class="p-button-rounded p-button-success btn-voltar align-self-end" />
        </div>
    </div>
</template>
<script>
import BaseService from '@/services/BaseService';
export default {
    data() {
        return {
            loading: false,
            dados: null
        };
    },
    created() {
        if (!this.$route.params.dadosPaciente) {
            this.voltar();
        }

        this.dados = JSON.parse(this.$route.params.dadosPaciente).data;
    },
    methods: {
        async voltar() {
            this.$router.push({ name: 'Validacao Check-In' });
        },
        async checkIn() {
            const service = new BaseService('/atendimento_check_in');
            try {
                this.loading = true;
                await service.save({ cpf: this.dados.funcionario.cpf });
                this.loading = false;
                this.$toast.add({
                    severity: 'success',
                    summary: 'Check-in realizado com sucesso!',
                    detail: 'Dirija-se até a sala de espera e aguarde seu nome ser chamado no telão.',
                    life: 8000
                });
                this.$router.go(-1);
            } catch (error) {
                this.$toast.add({
                    severity: 'warn',
                    summary: 'Erro ao Fazer Check-in! Por Favor tente novamente ou dirija-se a recepção',
                    detail: error.message,
                    life: 8000
                });
            }
        }
    }
};
</script>
<style scoped lang="scss">
.card-dados {
    border-radius: 15px;
    background-color: #faf5f5;
    height: calc(100% - 3.3vw);
}

.titulo {
    font-size: 3vh;
    font-weight: 400;
    margin: 0px;
}

.subtitulo {
    font-size: 2.3vh;
    font-weight: 400;
    margin: 0px;
}

.subtitulo-dados {
    font-size: 2.3vh;
    font-weight: 400;
    margin: 0px;
    margin-left: 4px;
    color: #a5a5a5;
}

.box-grid {
    display: grid;
    grid-template-areas:
        'dda ida'
        'rda rda'
        'footer footer';
    grid-template-rows: max(35vh) max(40vh) 6vh;
    column-gap: 1ch;
    row-gap: 0.1rem;
}

.detalhes-do-agendamento {
    grid-area: dda;
}

.instrucao-de-atendimento {
    grid-area: ida;
}

.resumo-do-atendimento {
    grid-area: rda;
}

.footer {
    grid-area: footer;
}

.btn-voltar {
    width: 13rem;
    height: 3.2rem;
    font-size: 2rem;
}

@media only screen and (max-width: 1000px) {
    .btn-voltar {
        width: 11rem;
        height: 3rem;
        font-size: 1.5rem;
    }

    .box-grid {
        grid-template-areas:
            'dda dda'
            'ida ida'
            'rda rda'
            'footer footer';
        grid-template-rows: 25vh 35vh 35vh 6vh;
    }

    .titulo {
        font-size: 2vh;
        font-weight: 400;
        margin-top: 5px;
    }

    .subtitulo {
        font-size: 1.8vh;
        font-weight: 400;
        margin: 0px;
    }

    .subtitulo-dados {
        font-size: 1.8vh;
        font-weight: 400;
        margin: 0px;
        margin-left: 4px;
        color: #a5a5a5;
    }
}
</style>
